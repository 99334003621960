import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import styles from './utils.module.scss';

export const HTML = (props) => {
	const html = {
		__html: props.content,
	};

	props = {
		...props,
		...{
			content: undefined,
		},
	};

	delete props.content;

	return <div {...props} dangerouslySetInnerHTML={html} />;
};

HTML.propTypes = {
	content: PropTypes.node.isRequired,
};

export const Conditional = ({ condition, wrapper, children, fallbackWrapper }) =>
	condition
		? wrapper(children)
		: (fallbackWrapper && fallbackWrapper(children)) || children;

Conditional.propTypes = {
	condition: PropTypes.any,
	fallbackWrapper: PropTypes.func,
	wrapper: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

export const ObfuscateLink = ({ href, onClick, children, ...props }) => {
	const clickHandler = useCallback(
		(event) => {
			event.preventDefault();
			window.location.href = href;
			onClick(event);
		},
		[onClick, href]
	);
	const protocol = useMemo(() => href.split(':')[0], [href]);

	return (
		<a onClick={clickHandler} href={`${protocol}:obfuscated`} {...props}>
			{children}
		</a>
	);
};

ObfuscateLink.defaultProps = {
	onClick: () => {},
};

ObfuscateLink.propTypes = {
	onClick: PropTypes.func,
	href: PropTypes.string.isRequired,
};

export const ObfuscateText = ({ text, className }) => {
	const processedClassName = useMemo(() => [className, styles.obfuscated].join(' '), [
		className,
	]);
	const processedText = useMemo(() => text.split('').reverse().join(''), [text]);

	return <span className={processedClassName}>{processedText}</span>;
};

ObfuscateText.propTypes = {
	onClick: PropTypes.func,
	href: PropTypes.string,
	text: PropTypes.string.isRequired,
};
