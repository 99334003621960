import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import { Slider } from '../slider';
import { ObfuscateLink } from '../utils';
import { PHONE_HREF } from '../../config/constants';

import styles from './start.module.scss';

export const Start = () => {
	const data = useStaticQuery(graphql`
		query start {
			location1: file(relativePath: { eq: "location-1.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1600) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			location2: file(relativePath: { eq: "location-2.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1600) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			manicure1: file(relativePath: { eq: "manicure-1.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1600) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			manicure2: file(relativePath: { eq: "manicure-2.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1600) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			pedicure1: file(relativePath: { eq: "pedicure-1.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1600) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			pedicure2: file(relativePath: { eq: "pedicure-2.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1600) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			accessoires: file(relativePath: { eq: "accessoires.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1600) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`);
	return (
		<div className={styles.wrapper}>
			<Slider fade={true} autoplay={true}>
				<Img
					alt="Salon Zirl"
					fluid={data.location1.childImageSharp.fluid}
					className={styles.image}
				/>
				<Img
					alt="Salon Fulpmes"
					fluid={data.location2.childImageSharp.fluid}
					className={styles.image}
				/>
				<Img
					alt="Maniküre 1"
					fluid={data.manicure1.childImageSharp.fluid}
					className={styles.image}
				/>
				<Img
					alt="Maniküre 2"
					fluid={data.manicure2.childImageSharp.fluid}
					className={styles.image}
				/>
				<Img
					alt="Fußpflege"
					fluid={data.pedicure1.childImageSharp.fluid}
					className={styles.image}
				/>
				<Img
					alt="Pediküre"
					fluid={data.pedicure2.childImageSharp.fluid}
					className={styles.image}
				/>
				<Img
					alt="Pflegeartikel"
					fluid={data.accessoires.childImageSharp.fluid}
					className={styles.image}
				/>
			</Slider>
			<div className={styles.teaser}>
				<h2>Willkommen in der &raquo;Werkstatt&laquo;</h2>
				<p>
					Genießen Sie Verwöhnprogramme für Hände und Füße, die weit über
					gewöhnliche Maniküre und Pediküre hinausgehen.
				</p>
				<ObfuscateLink href={PHONE_HREF} className={styles.button}>
					Termin vereinbaren
				</ObfuscateLink>
			</div>
		</div>
	);
};
