import React from 'react';
import PropTypes from 'prop-types';

import { Page } from '../layout';
import { CloseIcon } from '../icons';
import { ObfuscateText, ObfuscateLink } from '../utils';
import { ADDRESS, ADDRESS_ALTERNATE, PHONE_TEXT, EMAIL_TEXT, EMAIL_HREF } from '../../config/constants';

import styles from './legal.module.scss';

export const Legal = ({ onClose }) => (
	<Page
		title="Impressum & Datenschutz"
		subtitle="Alle rechtliche Informationen auf einen Blick">
		<div className={styles.wrapper}>
			{/* IMPRINT */}
			<div className={styles.section}>
				<h4>Impressum</h4>

				<h5>Herausgeber und Inhaltsverantwortlicher</h5>
				<p>
					Die Hand- und Fußwerkstatt EG
					<br />
					{ADDRESS}
					<br />
					<br/>
					und
					<br/>
					<br/>
					{ADDRESS_ALTERNATE}
				</p>
				<p>
					Telefon: <ObfuscateText text={PHONE_TEXT} />
					<br />
					Email: <ObfuscateText text={EMAIL_TEXT} />
					<br />
				</p>
				<p>Geschäftsführer: Schuler Claudia</p>
				<p>
					USt-IdNr.: ATU75360413
					<br />
					Handelsregister: Landesgericht Innsbruck 524391p
					<br />
					Zuständige Kammer: Bezirkshauptmannschaft Innsbruck Land
				</p>

				<h5>Bildquellen</h5>
				<p>
					<a href="http://www.freepik.com" target="_blank" rel="nofollow noopener">
						Freepik
					</a>
				</p>
			</div>

			{/* PRIVACY */}
			<div className={styles.section}>
				<h4>Datenschutzerklärung</h4>
				<p>
					Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der
					EU-Datenschutzgrundverordnung (DSGVO), ist:
				</p>
				<p>Schuler Claudia</p>
				<h5>Erfassung allgemeiner Informationen</h5>

				<p>
					Wenn Sie auf unsere Webseite zugreifen, werden automatisch Informationen
					allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa
					die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres
					Internet Service Providers und Ähnliches. Hierbei handelt es sich ausschließlich
					um Informationen, welche keine Rückschlüsse auf Ihre Person zulassen. Diese
					Informationen sind technisch notwendig, um von Ihnen angeforderte Inhalte von
					Webseiten korrekt auszuliefern und fallen bei Nutzung des Internets zwingend an.
					Anonyme Informationen dieser Art werden von uns statistisch ausgewertet, um
					unseren Internetauftritt und die dahinterstehende Technik zu optimieren.
				</p>

				<h5>Kontaktaufnahme</h5>

				<p>
					Treten Sie per E-Mail oder Kontaktformular mit uns in Kontakt, werden die von
					Ihnen gemachten Angaben zum Zwecke der Bearbeitung der Anfrage sowie für
					mögliche Anschlussfragen gespeichert.
				</p>

				<h5>Löschung bzw. Sperrung der Daten</h5>

				<p>
					Wir halten uns an die Grundsätze der Datenvermeidung und Datensparsamkeit. Wir
					speichern Ihre personenbezogenen Daten daher nur so lange, wie dies zur
					Erreichung der hier genannten Zwecke erforderlich ist oder wie es die vom
					Gesetzgeber vorgesehenen vielfältigen Speicherfristen vorsehen. Nach Fortfall
					des jeweiligen Zweckes bzw. Ablauf dieser Fristen werden die entsprechenden
					Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder
					gelöscht.
				</p>

				<h5>Verwendung von Scriptbibliotheken (Google Webfonts)</h5>

				<p>
					Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend
					darzustellen, verwenden wir auf dieser Website Scriptbibliotheken und
					Schriftbibliotheken wie z. B. Google Webfonts (
					<a
						href="https://www.google.com/webfonts/"
						target="_blank"
						rel="nofollow noopener">
						https://www.google.com/webfonts/
					</a>
					). Google Webfonts werden zur Vermeidung mehrfachen Ladens in den Cache Ihres
					Browsers übertragen. Falls der Browser die Google Webfonts nicht unterstützt
					oder den Zugriff unterbindet, werden Inhalte in einer Standardschrift angezeigt.
				</p>

				<p>
					Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst automatisch eine
					Verbindung zum Betreiber der Bibliothek aus. Dabei ist es theoretisch möglich –
					aktuell allerdings auch unklar ob und ggf. zu welchen Zwecken – dass Betreiber
					entsprechender Bibliotheken Daten erheben.
				</p>

				<p>
					Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier:{' '}
					<a
						href="https://www.google.com/policies/privacy/"
						target="_blank"
						rel="nofollow noopener">
						https://www.google.com/policies/privacy/
					</a>
				</p>

				<p>
					Ausführliche Anleitungen zur Verwaltung der eigenen Daten im Zusammenhang mit
					Google-Produkten{' '}
					<a
						href="http://www.dataliberation.org/"
						target="_blank"
						rel="nofollow noopener">
						finden Sie hier
					</a>
					.
				</p>

				<h5>Ihre Rechte auf Auskunft, Berichtigung, Sperre, Löschung und Widerspruch</h5>

				<p>
					Sie haben das Recht, jederzeit Auskunft über Ihre bei uns gespeicherten
					personenbezogenen Daten zu erhalten. Ebenso haben Sie das Recht auf
					Berichtigung, Sperrung oder, abgesehen von der vorgeschriebenen Datenspeicherung
					zur Geschäftsabwicklung, Löschung Ihrer personenbezogenen Daten. Bitte wenden
					Sie sich dazu an unseren Datenschutzbeauftragten. Die Kontaktdaten finden Sie
					ganz unten.
				</p>

				<p>
					Damit eine Sperre von Daten jederzeit berücksichtigt werden kann, müssen diese
					Daten zu Kontrollzwecken in einer Sperrdatei vorgehalten werden. Sie können auch
					die Löschung der Daten verlangen, soweit keine gesetzliche
					Archivierungsverpflichtung besteht. Soweit eine solche Verpflichtung besteht,
					sperren wir Ihre Daten auf Wunsch.
				</p>

				<p>
					Sie können Änderungen oder den Widerruf einer Einwilligung durch entsprechende
					Mitteilung an uns mit Wirkung für die Zukunft vornehmen.
				</p>

				<h5>SSL-Verschlüsselung</h5>

				<p>
					Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem
					aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL)
					über HTTPS.
				</p>

				<h5>Änderung unserer Datenschutzbestimmungen</h5>

				<p>
					Wir behalten uns vor, diese Datenschutzerklärung gelegentlich anzupassen, damit
					sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen
					unserer Leistungen in der Datenschutzerklärung umzusetzen, z. B. bei der
					Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue
					Datenschutzerklärung.
				</p>

				<h5>Fragen an den Datenschutzbeauftragten</h5>

				<p>
					Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder
					wenden Sie sich direkt an unseren Datenschutzbeauftragten:{' '}
					<ObfuscateLink href={EMAIL_HREF}>
						<ObfuscateText text={EMAIL_TEXT} />
					</ObfuscateLink>
				</p>

				<p>
					Die Datenschutzerklärung wurde mit dem{' '}
					<a
						href="https://www.activemind.de/datenschutz/datenschutzhinweis-generator/"
						target="_blank"
						rel="nofollow noopener">
						Datenschutzerklärungs-Generator der activeMind AG erstellt
					</a>
					.
				</p>
			</div>

			<button onClick={onClose} className={styles.close}>
				<CloseIcon />
			</button>
		</div>
	</Page>
);

Legal.defaultProps = {
	onClose: () => {},
};

Legal.propTypes = {
	onClose: PropTypes.func,
};
