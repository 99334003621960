import React from 'react';

import { ObfuscateLink } from './utils';
import { COMPANY } from '../config/constants';

import styles from './footer.module.scss';

export const Footer = () => (
	<footer className={styles.wrapper}>
		<div className={styles.copy}>&copy; {COMPANY} - Alle Rechte vorbehalten.</div>
		<div className={styles.links}>
			<ObfuscateLink href="mailto:armin.pfurtscheller.com" className={styles.link}>
				Made with ♥ in Tirol
			</ObfuscateLink>
		</div>
	</footer>
);
