import React from 'react';

import { Page } from '../layout';
import { LotusIcon } from '../icons';
import styles from './services.module.scss';

export const Services = () => (
	<Page
		title="Services"
		subtitle="Von podologischen Leistungen bis pflegende Verschönerungen">
		<div className={styles.sections}>
			<div className={styles.section}>
				<h4>Alles für die Füße</h4>
				<Item>Fußpflege</Item>
				<Item>Behandlung von Schwielen, eingewachsenen Nägeln & Hühneraugen</Item>
				<Item>Spangentechnik</Item>
				<Item>Orthesen</Item>
				<Item>Nagelprotetik</Item>
				<Item>Fußpeeling</Item>
				<Item>Fußmassage</Item>
				<Item>Paraffinbad für Füße</Item>
				<Item>Lackieren der Fußnägel</Item>
			</div>

			<div className={styles.section}>
				<h4>Alles für die Hände</h4>
				<Item>Maniküre</Item>
				<Item>Handpeeling</Item>
				<Item>Handmassage</Item>
				<Item>Paraffinbad für Hände</Item>
				<Item>Lackieren der Fingernägel</Item>
				<Item>Nagelaufbau mit Acryl oder Gel</Item>
			</div>
		</div>
	</Page>
);

const Item = ({ children }) => (
	<div className={styles.item}>
		<div className={styles.icon}>
			<LotusIcon />
		</div>
		<div className={styles.text}>{children}</div>
	</div>
);
