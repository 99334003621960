import React from 'react';
import { Helmet } from 'react-helmet';

import { LOCALE, COMPANY, TITLE, DESCRIPTION } from '../config/constants';

// import styles from './meta.module.scss';

export const Meta = () => {
    const title = `${TITLE} | ${COMPANY}`;

    return (
        <Helmet>
            <html lang={LOCALE} />
            <title>{title}</title>
            <meta name='robots' content='index, follow' />
            <meta name='description' content={DESCRIPTION} />
            <meta name='og:locale' content={LOCALE} />
            <meta name='og:type' content='website' />
            <meta name='og:title' content={title} />
            <meta name='og:description' content={DESCRIPTION} />
            <meta name='twitter:title' content={title} />
            <meta name='twitter:description' content={DESCRIPTION} />
            <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
            <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
            <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
            <link rel='manifest' href='/site.webmanifest' />
            <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#ff6316' />
            <meta name="msapplication-TileColor" content="#191919" />
            <meta name="theme-color" content="#ffffff" />
        </Helmet>
    );
};