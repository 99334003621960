import { useEffect, useState } from 'react';

const getWindowSize = () => {
  return {
    width: typeof window !== 'undefined' ? window.innerWidth : undefined,
    height: typeof window !== 'undefined' ? window.innerHeight : undefined,
  };
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const handler = () => setWindowSize(getWindowSize());

    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);

  return windowSize;
};
