import React from 'react';
import PropTypes from 'prop-types';

import { Conditional, ObfuscateLink, ObfuscateText } from './utils';
import { PinIcon, MailIcon, ClockIcon, SmartphoneIcon } from './icons';
import {
	ADDRESS,
	ADDRESS_ALTERNATE,
	PHONE_TEXT,
	PHONE_HREF,
	PHONE_TEXT_ALTERNATE,
	PHONE_HREF_ALTERNATE,
	EMAIL_TEXT,
	EMAIL_HREF,
} from '../config/constants';

import styles from './contacts.module.scss';

export const Hours = ({ title = "Öffnzungszeiten" }) => (
	<Contact
		icon={<ClockIcon />}
		title={title}
		subtitle="Mo.: 09:00 - 14.00 Uhr"
	/>
);

export const HoursAlternate = ({ title = "Öffnzungszeiten" }) => (
	<Contact
		icon={<ClockIcon />}
		title={title}
		subtitle="Mi. - Fr.: 09:00 - 14.00 Uhr"
	/>
);

export const Address = ({ title = "Adresse" }) => (
	<Contact
		tagName="address"
		icon={<PinIcon />}
		title={title}
		subtitle={ADDRESS}
		link="https://www.google.com/maps/dir/47.1019465,11.2974675/Bahnstra%C3%9Fe+4,+6166+Fulpmes"
	/>
);

export const AddressAlternate = ({ title = "Adresse" }) => (
	<Contact
		tagName="address"
		icon={<PinIcon />}
		title="Adresse"
		subtitle={ADDRESS_ALTERNATE}
		link="https://www.google.com/maps/dir/47.1019465,11.2974675/Meilstra%C3%9Fe+49a,+Zirl"
	/>
);

export const Phone = ({ title = "Telefon" }) => (
	<Contact
		icon={<SmartphoneIcon />}
		title={title}
		link={PHONE_HREF}
		obfuscateLink={true}
		subtitle={<ObfuscateText text={PHONE_TEXT} />}
	/>
);

export const Email = ({ title = "Email" }) => (
	<Contact
		icon={<MailIcon />}
		title={title}
		link={EMAIL_HREF}
		obfuscateLink={true}
		subtitle={<ObfuscateText text={EMAIL_TEXT} />}
	/>
);

const Contact = ({ tagName: CustomTag, link, icon, title, subtitle, obfuscateLink }) => (
	<Conditional
		condition={link}
		wrapper={(children) =>
			obfuscateLink ? (
				<ObfuscateLink href={link} className={styles.link}>
					{children}
				</ObfuscateLink>
			) : (
				<a href={link} className={styles.link} target="_blank">
					{children}
				</a>
			)
		}>
		<CustomTag className={styles.wrapper}>
			<div className={styles.icon}>{icon}</div>
			<div className={styles.text}>
				<div className={styles.title}>{title}</div>
				<div className={styles.subtitle}>{subtitle}</div>
			</div>
		</CustomTag>
	</Conditional>
);

Contact.defaultProps = {
	tagName: 'div',
};

Contact.propTypes = {
	link: PropTypes.string,
	tagName: PropTypes.string,
	obfuscateLink: PropTypes.bool,
	icon: PropTypes.element.isRequired,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};
