import React from 'react';
import PropTypes from 'prop-types';

import { Page } from '../layout';
import { LOCALE_REGION } from '../../config/constants';

import styles from './prices.module.scss';

export const Prices = () => (
	<Page title="Preise" subtitle="Alle Preise beinhalten 20% Mehrwertsteuer">
		<div className={styles.items}>
			<Item label="Medizinische Fußpflege" price={47} />
			<Item label="Kosmetische Fußpflege" price={43} />
			<Item label="Fußmassage (15min)" price={16} />
			<Item label="Fußmassage (45min)" price={47} />
			<Item label="Fußpeeling (15min)" price={15} />
			<Item label="Maniküre" price={32} />
			{/* <Item label="Verwöhnmaniküre (Maniküre, Massage, Lack, etc.)" price={60} /> */}
			<Item label="Gel-Lack bei Fußpflege/Maniküre" price={25} />
			<Item label="Normal-Lack" price={7} />
			<Item label="Paraffinbad (Hände/Füße)" price={20} />
			<Item label="Nagelspange 3TO setzen" price={89} />
			<Item label="Nagelspange nachsetzen" price={15} />
			<Item label="Klebe-Spange" price={45} />
		</div>

		<p>
			<strong>Hinweis:</strong> Natürlich erhalten Sie auch diverse Pflegeprodukte sowie
			Gutscheine!
		</p>
	</Page>
);

const Item = ({ label, price }) => (
	<div className={styles.item}>
		<div className={styles.label}>
			<span className={styles.wrapper}>{label}</span>
		</div>
		<div className={styles.price}>
			<em className={styles.wrapper}>
				{price.toLocaleString(LOCALE_REGION, {
					currency: 'EUR',
					style: 'currency',
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				})}
			</em>
		</div>
	</div>
);

Item.propTypes = {
	label: PropTypes.string.isRequired,
	price: PropTypes.number.isRequired,
};
