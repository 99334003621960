import { useMemo, useEffect, useState, useCallback } from 'react';

const mockMediaQueryList = {
	media: '',
	matches: false,
	onchange: () => {},
	addListener: () => {},
	removeListener: () => {},
	addEventListener: () => {},
	removeEventListener: () => {},
	dispatchEvent: () => true,
};

export const useMatchMedia = (expr) => {
	const handler = useCallback(({ matches }) => setMatches(matches), []);
	const mql = useMemo(
		() => (typeof window !== 'undefined' ? window.matchMedia(expr) : mockMediaQueryList),
		[expr]
	);
	const [matches, setMatches] = useState(mql.matches);

	useEffect(() => {
		mql.addListener(handler);

		return () => mql.removeListener(handler);
	}, [mql, handler]);

	return matches;
};