import React from 'react';
import Slick from 'react-slick';
import PropTypes from 'prop-types';

import { ArrowLeftIcon, ArrowRightIcon } from './icons';

import './slider.module.scss';

const PrevArrow = ({ className, onClick }) => (
	<button onClick={onClick} className={className}>
		<ArrowLeftIcon />
	</button>
);

const NextArrow = ({ className, onClick }) => (
	<button onClick={onClick} className={className}>
		<ArrowRightIcon />
	</button>
);

export const Slider = props => {
	const {
		dots,
		arrows,
		children,
		className,
		inlineArrows,
		slidesToShow,
		slidesToScroll
	} = props;
	const items = typeof slidesToShow === 'number' ? slidesToShow : 1;
	const total = Array.isArray(children) ? children.length : 0;
	const hasSlides = Math.ceil(total / items) > 1;

	props = {
		...props,
		...{
			children: undefined,
			className: undefined,
			inlineArrows: undefined,
			slidesToShow: (slidesToShow > total && total) || slidesToShow,
			slidesToScroll: (slidesToScroll > total && total) || slidesToScroll
		}
	};

	

	delete props.children;
	delete props.className;
	delete props.inlineArrows;

	return (
		<div
			className={[
				className || '',
				(dots && hasSlides && 'slick--has-dots') || '',
				(arrows && hasSlides && 'slick--has-arrows') || '',
				(arrows && inlineArrows && 'slick--inline-arrows') || ''
			].filter(Boolean).join(' ')}>
			<Slick {...props}>{children}</Slick>
		</div>
	);
};

Slider.defaultProps = {
	fade: false,
	dots: false,
	arrows: false,
	lazyLoad: true,
	infinite: true,
	autoplay: false,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplaySpeed: 4000,
	inlineArrows: false,
	adaptiveHeight: false,
	nextArrow: <NextArrow />,
	prevArrow: <PrevArrow />
};

Slider.propTypes = {
	dots: PropTypes.bool,
	fade: PropTypes.bool,
	arrows: PropTypes.bool,
	autoplay: PropTypes.bool,
	lazyLoad: PropTypes.bool,
	infinite: PropTypes.bool,
	autoplaySpeed: PropTypes.number,
	slidesToShow: PropTypes.number,
	slidesToScroll: PropTypes.number,
	inlineArrows: PropTypes.bool,
	adaptiveHeight: PropTypes.bool,
	nextArrow: PropTypes.node,
	prevArrow: PropTypes.node
};