import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { Logo } from '../components/logo';
import { Meta } from '../components/meta';
import { Footer } from '../components/footer';
import { MenuIcon } from '../components/icons';
import { Hours, HoursAlternate, Phone } from '../components/contacts';

import { Start } from '../components/pages/start';
import { Services } from '../components/pages/services';
import { Prices } from '../components/pages/prices';
import { About } from '../components/pages/about';
import { Contact } from '../components/pages/contact';
import { Legal } from '../components/pages/legal';

import { useMatchMedia } from '../hooks/use-match-media';
import { useWindowSize } from '../hooks/use-window-size';
import { LG } from '../utils/breakpoints';

import styles from './index.module.scss';

const START_ID = 'start';
const ABOUT_ID = 'ueber-mich';
const SERVICES_ID = 'leistungen';
const PRICES_ID = 'preise';
const CONTACT_ID = 'kontakt';
const LEGAL_ID = 'impressum-datenschutz';

const getIdFromHashOrURL = (value) => {
	const parts = value.split('#');
	return parts[1] || parts[0];
};

const scrollToPageByHashOrId = (value) => {
	setTimeout(() => {
		const id = getIdFromHashOrURL(value);
		const page = document.getElementById(id);
		if (page === null) {
			return;
		}

		window.scroll({ top: page.offsetTop, behavior: 'smooth' });
		window.history.pushState(null, null, `#${id}`);
	}, 1);
};

const Site = () => {
	const isLG = useMatchMedia(`(min-width: ${LG}px)`);
	const { height: viewportHeight } = useWindowSize();
	const [startStyle, setStartStyle] = useState({});
	const [showLegal, setShowLegal] = useState(false);
	const [showSidebar, setShowSidebar] = useState(false);

	const clickToggleMenuHandler = useCallback(() => setShowSidebar(!showSidebar), [
		showSidebar,
	]);
	const clickBackDropHandler = useCallback(() => setShowSidebar(false));

	const clickMenuHandler = useCallback((event) => {
		const {
			target: { href },
		} = event;
		const id = getIdFromHashOrURL(href);

		event.preventDefault();

		setShowSidebar(false);
		setShowLegal(id === LEGAL_ID);

		scrollToPageByHashOrId(href);
	});

	const clickCloseLegalHandler = useCallback(() => {
		setShowLegal(false);
		scrollToPageByHashOrId(CONTACT_ID);
	});

	useEffect(() => {
		const toggleLegalSectionFromHash = (hash) => {
			const id = getIdFromHashOrURL(hash);
			setShowLegal(id === LEGAL_ID);
		};

		const handler = (event) => {
			event.preventDefault();

			setShowSidebar(false);

			const { hash } = window.location;
			toggleLegalSectionFromHash(hash);
			scrollToPageByHashOrId(hash);
		};

		toggleLegalSectionFromHash(window.location.hash);
		window.addEventListener('hashchange', handler);

		return () => window.removeEventListener('hashchange', handler);
	}, []);

	useEffect(() => {
		setStartStyle(!isLG ? { height: `${viewportHeight}px` } : {});
	}, [isLG, viewportHeight]);

	useEffect(() => {
		setShowSidebar(isLG);
	}, [isLG]);

	return (
		<Fragment>
			<Meta />
			<Helmet
				bodyAttributes={{
					class: !isLG && showSidebar ? styles.sidebarOpened : '',
				}}
			/>
			<div className={styles.wrapper}>
				<button onClick={clickToggleMenuHandler} className={styles.toggle}>
					<MenuIcon isActive={showSidebar} />
				</button>

				<aside className={styles.aside}>
					<div className={styles.top}>
						<div className={styles.logo}>
							<Logo />
						</div>

						<nav className={styles.menu}>
							<a
								href={`#${START_ID}`}
								onClick={clickMenuHandler}
								className={styles.menulink}>
								Start
							</a>
							<a
								href={`#${SERVICES_ID}`}
								onClick={clickMenuHandler}
								className={styles.menulink}>
								Services
							</a>
							<a
								href={`#${ABOUT_ID}`}
								onClick={clickMenuHandler}
								className={styles.menulink}>
								Über mich
							</a>
							<a
								href={`#${PRICES_ID}`}
								onClick={clickMenuHandler}
								className={styles.menulink}>
								Preise
							</a>
							<a
								href={`#${CONTACT_ID}`}
								onClick={clickMenuHandler}
								className={styles.menulink}>
								Kontakt
							</a>
						</nav>

						<div className={styles.contacts}>
							
							<div className={styles.contactitem}>
								<h5>Standort Fulpmes</h5>
								<Hours />
							</div>
							
							<div className={styles.contactitem}>
								<h5>Standort Zirl</h5>
								<HoursAlternate />
							</div>

							<div className={styles.contactitem}>
								<h5>Terminanfrage</h5>
								<Phone />
							</div>
						</div>
					</div>

					<div className={styles.bottom}>
						<a
							href={`#${LEGAL_ID}`}
							onClick={clickMenuHandler}
							className={styles.legallink}>
							Impressum & Datenschutz
						</a>
					</div>
				</aside>

				<main className={styles.main}>
					<button onClick={clickBackDropHandler} className={styles.backdrop}></button>

					<div id={START_ID} style={startStyle} className={styles.start}>
						<Start />
					</div>

					<div id={SERVICES_ID}>
						<Services />
					</div>

					<div id={ABOUT_ID} className={styles.about}>
						<About />
					</div>

					<div id={PRICES_ID} className={styles.prices}>
						<Prices />
					</div>

					<div id={CONTACT_ID} className={styles.contact}>
						<Contact />
					</div>

					<div
						id={LEGAL_ID}
						className={[styles.legal, showLegal ? styles.showlegal : ''].join(' ')}>
						<Legal onClose={clickCloseLegalHandler} />
					</div>

					<Footer />
				</main>
			</div>
		</Fragment>
	);
};

export default Site;
