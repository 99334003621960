export const COMPANY = 'Die Hand- & Fußwerkstatt';
export const TITLE = 'Maniküre, Pediküre & Fußpflege in Zirl und Fulpmes';
export const DESCRIPTION =
	'Von podologischen Leistungen bis bis pflegende Verschönerungen - Verwöhnprogramme für Hände und Füße weit über gewöhnliche Maniküre und Fußpflege hinaus.';

export const LOCALE = 'de';
export const LOCALE_REGION = 'de-DE';

export const ADDRESS = 'Bahnstraße 4, 6166 Fulpmes';
export const ADDRESS_ALTERNATE = 'Meilstraße 49a, 6170 Zirl';

export const PHONE_TEXT = '+43 664 23 24 026';
export const PHONE_HREF = `tel:${PHONE_TEXT.replace(/ /g, '')}`;

export const EMAIL_TEXT = 'info@handundfusswerkstatt.com';
export const EMAIL_HREF = `mailto:${EMAIL_TEXT}`;
