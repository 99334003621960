import React from 'react';

import ArrowLeftSVG from '../icons/arrow-left.svg';
import ArrowRightSVG from '../icons/arrow-right.svg';
import ClockSVG from '../icons/clock.svg';
import CloseSVG from '../icons/close.svg';
import LotusSVG from '../icons/lotus.svg';
import MailSVG from '../icons/mail.svg';
import PinSVG from '../icons/pin.svg';
import SmartphoneSVG from '../icons/smartphone.svg';

import styles from './icons.module.scss';

export const ArrowLeftIcon = (props) => (
  <Icon icon={ArrowLeftSVG} {...props}/>
);

export const ArrowRightIcon = (props) => (
  <Icon icon={ArrowRightSVG} {...props}/>
);

export const CloseIcon = (props) => (
  <Icon icon={CloseSVG} {...props}/>
);

export const ClockIcon = (props) => (
  <Icon icon={ClockSVG} {...props}/>
);

export const LotusIcon = (props) => (
  <Icon icon={LotusSVG} {...props}/>
);

export const MailIcon = (props) => (
  <Icon icon={MailSVG} {...props}/>
);

export const PinIcon = (props) => (
  <Icon icon={PinSVG} {...props}/>
);

export const SmartphoneIcon = (props) => (
  <Icon icon={SmartphoneSVG} {...props}/>
);

export const MenuIcon = ({ isActive, className }) => (
	<svg
		viewBox='0 0 40 40'
		className={`
			${styles.menu}
			${className ? ` ${className}` : ''}
			${isActive ? ` ${styles.menuActive}` : ''}
		`}>
		<g className={styles.menuLines}>
			<line
				x1='0'
				x2='40'
				y1='5'
				y2='5'
				className={styles.menuLine1}>
			</line>
			<line
				x1='0'
				x2='40' 
				y1='20'
				y2='20'
				className={styles.menuLine2}>
			</line>
			<line
				x1='0'
				x2='40' 
				y1='20'
				y2='20'
				className={styles.menuLine3}>
			</line>
			<line
				x1='0'
				x2='40'
				y1='35'
				y2='35' 
				className={styles.menuLine4}>
			</line>
		</g>
	</svg>
);

const Icon = (props) => {
	const { icon: { id, viewBox }, className = '' } = props;
	props = {
		...props,
		...{
			className: `${styles.icon} ${className}`
		}
	}

	delete props.icon;

	return (
		<svg viewBox={viewBox} {...props}>
	    	<use xlinkHref={`#${id}`} />
	  	</svg>
	);
};

export default Icon;