import React from 'react';

import styles from './logo.module.scss';

export const Logo = () => (
	<div className={styles.wrapper}>
		<h1 className={styles.title}>die Hand &amp; Fußwerkstatt</h1>
		<div className={styles.subtitle}>Meisterbetrieb</div>
	</div>
);
