import React from 'react';
import PropTypes from 'prop-types';

import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import styles from './about.module.scss';

import { Page, THEME_DARK } from '../layout';

export const About = () => {
	const data = useStaticQuery(graphql`
		query about {
			claudia: file(relativePath: { eq: "claudia.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 250) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);
	return (
		<Page
			theme={THEME_DARK}
			title="Über mich"
			subtitle="Ein Blick hinter die Kulissen in der Hand & Fußwerkstatt">
			<Profile image={data.claudia} title="Fußpflege ist nicht gleich Fußpflege">
				<p>
					Ich sehe meine Aufgabe als Podologin darin, die Gesundheit der Füße	in den Vordergrund 
					zu rücken. Es gibt viele Menschen, die einen Fachmann auf diesem Gebiet benötigen. Ob 
					Junge oder Ältere, Diabetiker oder Schmerzklienten - alle benötigen die Arbeit des 
					Fußpflegers. Die Arbeitspalette reicht von Fußpflege bis hin zu den verschiedensten 
					Spezialgebiete wie z.B.	Spangen-Technik.
				</p>
				<p>
					Zuguterletzt sollte aber auch das Wohlfühlen keinesfalls zu kurz kommen. Hierzu
					wird die Behandlung mit einem schönen, klassischen oder trendigen Nagellack, mit
					einem erfrischenden Peeling oder einer entspannenden Fußmassage abgerundet!
				</p>

				<p>
					<strong className={styles.name}>Schuler Claudia</strong>
					<em className={styles.job}>Fußpflegemeisterin</em>
				</p>
			</Profile>

			{/*<Profile image={data.viktoria} title="Maniküre ist nicht gleich Maniküre">
				<p>
					Wir bieten in unserer Hand & Fußwerkstatt eine Maniküre der besonderen Art an!
					Unsere Leistungen reichen von der Maniküre bis hin zum Handpeeling, Handmassage
					und zuguterletzt ein Paraffinbad für Hand oder auch Füße an!
				</p>
				<p>
					Den Abschluß einer Maniküre bilden perfekt lackierte Nägel - denn: Hände und
					Füße sind die Visitenkarten eines Menschen! Wir kommen den Wünschen eines
					Nagelaufbaus mit Acryl oder Gel unserer Kunden gerne nach!
				</p>

				<p>
					<strong className={styles.name}>Heider Viktoria</strong>
					<em className={styles.job}>Maniküristin</em>
				</p>
			</Profile>*/}
		</Page>
	);
};

const Profile = ({ image, title, subtitle, children }) => (
	<div className={styles.profile}>
		<Img fluid={image.childImageSharp.fluid} className={styles.image} />
		<div className={styles.content}>
			<h4 className={styles.title}>{title}</h4>

			{children}
		</div>
	</div>
);

Profile.propTypes = {
	image: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired,
};
