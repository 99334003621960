import React from 'react';
import PropTypes from 'prop-types';

import styles from './layout.module.scss';

export const THEME_LIGHT = 'LIGHT';
export const THEME_DARK = 'DARK';

export const Page = ({ theme, title, subtitle, children }) => (
	<div className={[styles.page, `${styles[theme.toLowerCase()]}`].join(' ')}>
		<div className={styles.header}>
			<h2 className={styles.title}>{title}</h2>
			<h3 className={styles.subtitle}>{subtitle}</h3>
		</div>
		<div className={styles.content}>{children}</div>
	</div>
);

Page.defaultProps = {
	theme: THEME_LIGHT,
};

Page.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	theme: PropTypes.oneOf([THEME_LIGHT, THEME_DARK]),
};
